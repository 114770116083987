<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-icon
          @click="goBack"
          size="large"
          color="primary"
          slot="start"
          :icon="chevronBackOutline"
        ></ion-icon>
        <ion-label class="fw-600 fs-3" @click="goBack">{{ $t('select_payment') }} </ion-label>
      </ion-toolbar>
    </ion-header>
    <skeleton-select-payment v-if="loadingInit" />
    <ion-content v-else>
      <ion-grid class="mb-2" v-if="!isCreateTopUpPayment">
        <ion-row class="ion-align-items-center">
          <ion-col>
            <ion-row>
              <div class="d-flex align-center ml-1">
                <ion-icon class="i-help" :icon="helpCircleOutline"></ion-icon>
                <ion-label class="fs-2 ml-1 fw-500 text-gray-700">
                  {{ $t('use_available_credits') }}
                </ion-label>
              </div>
            </ion-row>
            <ion-row>
              <ion-label
                class="ml-6 mt-1 fw-500"
                :class="{
                  'text-primary-orange-500': remainingCredits <= 0,
                  'text-primary-green-500': remainingCredits > 0
                }"
                >{{ $t('remaining_credits') }}
                {{ priceFormatter(currencySymbol, remainingCredits) }}</ion-label
              >
            </ion-row>
          </ion-col>
          <ion-col size="auto">
            <ion-toggle ref="toggleRef" v-model="isChecked" :disabled="!availableCreditAmount"></ion-toggle>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div class="spacer" v-if="!isCreateTopUpPayment"></div>
      <card-payment
        ref="cardPaymentRef"
        @onChangePaymentType="onPaymentSelected"
        :userTypeId="activeUser?.user_type_id"
        :listCards="mapStripeCard"
        :isSgMyUser="isSgMyUser"
        :isFullyPaidCustBalance="isFullyPaidCustBalance"
        :totalPaidAmount="totalPaidAmount"
      />
      <div class="spacer" v-if="!isCreateTopUpPayment"></div>
      <comp-summary-info
        v-if="!isCreateTopUpPayment"
        :selectedInvoices="selectedInvoicesData"
        :currencySymbol="currencySymbol"
        :customerCreditAllocation="customerCreditAllocation"
        :totalInvoicesAmount="totalInvoicesAmount"
        :totalInvoice="totalInvoicesAmount"
        :grandTotal="grandTotal"
        :totalPaidAmount="totalPaidAmount"
      />
      <div class="spacer" v-if="!isCreateTopUpPayment"></div>
    </ion-content>
    <skeleton-bottom v-if="loadingInit" />
    <comp-bottom-content
      v-else
      @handlePayInvoices="enterPayment"
      @editPayment="toggleEditPayment = true"
      :totalPaidAmount="totalPaidAmount"
      :currencySymbol="currencySymbol"
      :paymentType="paymentType"
      :isDigitalPayment="isDigitalPayment"
      :isFullyPaidCustBalance="isFullyPaidCustBalance"
    />
    <modal-input-credits
      :isShowInputCredit="isShowInputCredit"
      @closeModal="closeModalInputCredit"
      @onUseCreditBalance="onUseCreditBalance"
      :currencySymbol="currencySymbol"
      :initialCredit="initialCreditValue"
      :remainingCredits="availableCreditAmount"
    />

    <modal-edit-payment
      ref="modalEditPaymentRef"
      :toggleEditPayment="toggleEditPayment"
      @close-modal="toggleEditPayment = false"
      @onEditPaidAmount="onEditPaidAmount"
      :currencySymbol="currencySymbol"
      :totalOutstanding="grandTotal"
      :totalPaidAmount="totalPaidAmount"
      :minimumOutstandingAmount="minimumOutstandingAmount"
    />
    <ion-modal mode="ios" :is-open="isDigitalPaymentSummaryOpen" :backdropDismiss="false">
      <modal-digital-payment-summary
        @backToInvoice="backToInvoice"
        @backToAccount="backToAccount"
        @backToHome="backToHome"
        :currencySymbol="currencySymbol"
        :paymentSummary="paymentSummary"
        :invoices="selectedInvoicesData"
        :paymentType="paymentType"
        :appliedCredit="customerCreditAllocation"
        :grandTotal="grandTotal"
        :totalPaidAmount="totalPaidAmount"
      />
    </ion-modal>
    <ion-loading
      :is-open="isLoadingCreatePayment"
      cssClass="my-custom-class"
      :message="`${$t('please_wait')}...`"
      spinner="crescent"
    >
    </ion-loading>
  </ion-page>
</template>
<script>
// packages
import { ACTIONS } from '@/modules/sale/store/payment/actions';
import { INVOICE_PAYMENT_METHOD, INVOICE_PAYMENT_TYPE, USER_COUNTRY } from '@/modules/shared/constants/';
import { toastError, useAlert } from '@/modules/shared/utils';
import { useConfig, useGetStorageData } from '@/usecases/';
import { priceFormatter } from '@/utils/';
import { chevronBackOutline, helpCircleOutline } from 'ionicons/icons';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { createNamespacedHelpers } from 'vuex';
// components
import {
  ModalInputCredits,
  SkeletonBottom,
  SkeletonSelectPayment
} from '@/modules/shared/components/invoices-payment';
import { useBackButton } from '@ionic/vue';
import dayjs from 'dayjs';
import {
  CardPayment,
  CompBottomContent,
  CompSummaryInfo,
  ModalDigitalPaymentSummary,
  ModalEditPayment
} from './components';

const { mapGetters, mapActions } = createNamespacedHelpers('sale/payment');

export default defineComponent({
  name: 'select-payment-sale',
  components: {
    CompBottomContent,
    CompSummaryInfo,
    CardPayment,
    ModalInputCredits,
    ModalEditPayment,
    SkeletonBottom,
    SkeletonSelectPayment,
    ModalDigitalPaymentSummary
  },
  setup() {
    const { router, store, t } = useConfig();
    const { selectedUser, selectedCompany } = useGetStorageData();
    const { createAlert } = useAlert();
    const isShowInputCredit = ref(false);
    const toggleEditPayment = ref(false);
    const isDigitalPaymentSummaryOpen = ref(false);
    const isChecked = ref(false);
    const loadingInit = ref(false);
    const isLoadingCreatePayment = ref(false);
    const activeUser = ref(null);
    const setOpenLoadingInit = (state) => {
      loadingInit.value = state;
    };
    const setLoadingAddCard = (state) => {
      isLoadingCreatePayment.value = state;
    };
    const goBack = async () => {
      await router.back();
      setOpenLoadingInit(true);
      await store.dispatch(`sale/payment/${ACTIONS.RESET_PARAMS_TOP_UP_CREDIT}`);
    };
    const navigateToRoute = async (routePath) => {
      isDigitalPaymentSummaryOpen.value = false;
      await store.dispatch(`sale/payment/${ACTIONS.REMOVE_SELECTED_PAYMENT_INVOICES}`);
      router.replace(routePath);
    };
    const backToInvoice = () => navigateToRoute('/sale/invoices/select-invoices');
    const backToAccount = () => navigateToRoute('/sale/account');
    const backToHome = () => navigateToRoute('/sale/main/home');
    const isCreateTopUpPayment = computed(
      () => store.getters[`sale/payment/paramsTopUpCredit`]?.totalPaidAmount > 0 ?? false
    );
    const paramsTopUp = computed(() => store.getters[`sale/payment/paramsTopUpCredit`]);
    const listStripeCard = computed(() => store.getters[`sale/payment/saleListStripeCard`]);
    const defaultStripeCardId = computed(() => store.getters[`sale/payment/defaultCardId`]);
    const genericError = computed(() => store.getters[`sale/payment/error`]);
    const mapStripeCard = computed(() => {
      if (listStripeCard.value && listStripeCard.value.data) {
        const cardData = listStripeCard.value.data.map((item) => ({
          ...item,
          isDefault: defaultStripeCardId?.value ? item.id === defaultStripeCardId.value : false
        }));
        cardData.sort((a, b) => (a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1));
        return cardData;
      } else {
        return [];
      }
    });
    const getDefaultAndListStripeCard = async () => {
      const { id: customerId } = await selectedCompany.value;
      if (!customerId) {
        createAlert(
          t('missing_customers_data_label'),
          t('missing_customers_data_desc'),
          () => navigateToRoute('/sale/select-customer'),
          t('selectCompany.select_company')
        );
        return;
      }
      await store.dispatch(`sale/payment/${ACTIONS.GET_DEFAULT_CARD}`, customerId);
      await store.dispatch(`sale/payment/${ACTIONS.SALE_LIST_STRIPE_CARD}`, customerId);
    };

    useBackButton(10, () => {
      router.back();
    });

    watch(genericError, () => {
      if (genericError.value) {
        createAlert(t('something_went_wrong_please_try_again'), genericError.value.message, () =>
          backToHome()
        );
      }
    });

    onMounted(async () => {
      setOpenLoadingInit(true);
      activeUser.value = await selectedUser.value;
      await getDefaultAndListStripeCard();
      setOpenLoadingInit(false);
    });
    return {
      mapStripeCard,
      activeUser,
      selectedCompany,
      paramsTopUp,
      isCreateTopUpPayment,
      goBack,
      chevronBackOutline,
      isShowInputCredit,
      helpCircleOutline,
      isChecked,
      toggleEditPayment,
      priceFormatter,
      INVOICE_PAYMENT_TYPE,
      customerCreditAllocation: ref(0),
      remainingCredits: ref(0),
      totalOverpayment: ref(0),
      totalOverdue: ref(0),
      totalPaidAmount: ref(0),
      selectedInvoicesData: ref([]),
      loadingInit,
      setOpenLoadingInit,
      digitalCardIdSelected: ref(null),
      isDigitalPaymentSummaryOpen,
      backToHome,
      backToAccount,
      setLoadingAddCard,
      isLoadingCreatePayment,
      backToInvoice,
      paymentType: ref(null),
      isFullyPaidCustBalance: ref(false),
      toastError,
      createAlert
    };
  },

  watch: {
    customerCreditAllocation(value) {
      this.updateRemainingBalance(value);
      if (value > 0 && !this.paymentType) {
        this.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
        this.$refs.cardPaymentRef.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
      }
      if (value === +this.totalInvoicesAmount.toFixed(2)) {
        this.isFullyPaidCustBalance = true;
        this.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
        this.$refs.cardPaymentRef.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
        this.$refs.cardPaymentRef.digitalCardIdSelected = null;
      } else {
        this.isFullyPaidCustBalance = false;
      }
    },
    selectedInvoicesData(value) {
      this.customerCreditAllocation = 0;
      this.$nextTick(() => {
        if (this.$refs.toggleRef) {
          this.$refs.toggleRef.$el.checked = false;
        }
      });
      this.selectedInvoicesData = value;
    },
    isChecked(value) {
      if (value) {
        this.isShowInputCredit = true;
      } else {
        this.isShowInputCredit = false;
        if (this.customerCreditAllocation > 0) {
          this.customerCreditAllocation = 0;
        }
      }
    },
    totalPaidAmount(value) {
      this.totalPaidAmount = Number(value);
      this.$refs.modalEditPaymentRef.paidAmount = +this.totalPaidAmount.toFixed(2);
      if (this.customerCreditAllocation > 0 && this.totalPaidAmount === 0) {
        this.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
        this.$refs.cardPaymentRef.paymentType = INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT;
      }
    }
  },
  ionViewDidEnter() {
    this.resetCompState();
  },
  computed: {
    ...mapGetters(['paymentSummary', 'selectedPaymentInvoices', 'error', 'paramsTopUpCredit']),
    selectedInvoices() {
      return this.selectedPaymentInvoices?.selectedInvoices ?? [];
    },
    totalInvoicesAmount() {
      return this.selectedPaymentInvoices?.totalInvoiceAmount;
    },
    currencySymbol() {
      return this.isCreateTopUpPayment
        ? this.paramsTopUp.currencySymbol
        : this.selectedPaymentInvoices?.selectedInvoices[0]?.currencySymbol;
    },
    availableCreditAmount() {
      return +this.selectedPaymentInvoices?.availableCreditAmount;
    },
    initialCreditValue() {
      if (this.availableCreditAmount > this.totalInvoicesAmount) {
        return this.totalInvoicesAmount;
      } else {
        return this.selectedPaymentInvoices?.availableCreditAmount - this.customerCreditAllocation;
      }
    },
    grandTotal() {
      return this.totalInvoicesAmount - this.customerCreditAllocation;
    },
    minimumOutstandingAmount() {
      const invoices = this.selectedInvoices;
      const sortInvoices = invoices.sort(
        (invBefore, invAfter) => invBefore.invoiceDate - invAfter.invoiceDate
      );
      const latestInvoice = sortInvoices[sortInvoices.length - 1];
      return latestInvoice?.outstandingAmount ?? 0;
    },
    isDigitalPayment() {
      return INVOICE_PAYMENT_METHOD.DIGITAL.includes(this.paymentType);
    },
    isSgMyUser() {
      return [USER_COUNTRY.SG, USER_COUNTRY.MY].includes(this.activeUser?.country?.id);
    }
  },
  methods: {
    ...mapActions([
      ACTIONS.SET_SELECTED_PAYMENT_INVOICES,
      ACTIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS,
      ACTIONS.SET_PARAMS_TOP_UP_CREDIT,
      ACTIONS.CREATE_TOP_UP_CREDIT
    ]),

    closeModalInputCredit() {
      this.isShowInputCredit = false;
      if (!this.customerCreditAllocation) {
        this.remainingCredits = this.availableCreditAmount;
        this.isChecked = false;
      } else {
        this.isChecked = true;
      }
    },
    onUseCreditBalance(amount) {
      if (+amount > this.remainingCredits) {
        this.toastError(this.$t('input_amount_error'));
        return;
      } else if (+amount > +this.grandTotal.toFixed(2)) {
        this.toastError(this.$t('input_amount_error_2'));
        return;
      } else if (+amount < 0) {
        this.toastError(this.$t('invalid_amount_negative'));
        return;
      } else {
        this.customerCreditAllocation = +amount.toFixed(2);
      }
    },
    resetCompState() {
      this.remainingCredits = this.availableCreditAmount;
      this.totalPaidAmount = this.isCreateTopUpPayment ? +this.paramsTopUp.totalPaidAmount : this.grandTotal;
      this.selectedInvoicesData = this.selectedInvoices;
      this.totalOverpayment = 0;
      this.totalOverdue = 0;
      this.paymentType = null;
      this.$nextTick(() => {
        if (this.$refs.cardPaymentRef) {
          this.$refs.cardPaymentRef.paymentType = null;
          this.$refs.cardPaymentRef.digitalCardIdSelected = null;
        }
        if (this.$refs.toggleRef) {
          this.$refs.toggleRef.$el.checked = false;
        }
        if (this.$refs.modalEditPaymentRef) {
          this.$refs.modalEditPaymentRef.overdueAmount = 0;
          this.$refs.modalEditPaymentRef.overpaymentAmount = 0;
          this.$refs.modalEditPaymentRef.paidAmount = this.totalInvoicesAmount;
          this.$refs.modalEditPaymentRef.subTotalAmount = this.totalInvoicesAmount;
        }
      });
    },
    updateRemainingBalance(value) {
      this.remainingCredits = this.availableCreditAmount - value;
      this.totalPaidAmount = this.grandTotal;
      this.$refs.modalEditPaymentRef.subTotalAmount = this.grandTotal;
    },
    onEditPaidAmount(totalPaidAmount, totalOverpayment, totalOverdue) {
      if (totalPaidAmount < 0) {
        this.toastError(this.$t('invalid_amount_negative'));
        return;
      }
      this.totalPaidAmount = totalPaidAmount;
      this.totalOverpayment = totalOverpayment;
      this.totalOverdue = totalOverdue;
    },
    onPaymentSelected(paymentType, digitalCardIdSelected) {
      this.paymentType = paymentType;
      this.digitalCardIdSelected = digitalCardIdSelected;
    },
    async enterPayment() {
      if (this.isDigitalPayment || this.isFullyPaidCustBalance) {
        this.setLoadingAddCard(true);
        await this.submitDigitalPayment();
        if (this.error) {
          this.setLoadingAddCard(false);
          const error3dSecure = this.error.message.startsWith(
            'This payment requires additional authorization'
          );
          if (error3dSecure) {
            this.createAlert(this.$t('unable_to_authorize'), this.error?.message, this.$t('OK'));
          } else {
            this.createAlert(
              this.$t('payment_creation_failed'),
              this.error?.message,
              this.backToInvoice,
              this.$t('OK')
            );
          }
        } else {
          this.setLoadingAddCard(false);
          this.isDigitalPaymentSummaryOpen = true;
        }
      } else {
        await this.submitManualPayment();
      }
    },
    async submitDigitalPayment() {
      if (this.isCreateTopUpPayment) {
        await this[ACTIONS.SET_PARAMS_TOP_UP_CREDIT]({
          paramsTopUpCredit: {
            ...this.paramsTopUpCredit,
            paymentTypeId: this.paymentType,
            stripeCardId: this.digitalCardIdSelected,
            transactionDate: dayjs(new Date()).format('YYYY-MM-DD'),
            paymentProof: [],
            referenceNumber: ''
          }
        });
        await this[ACTIONS.CREATE_TOP_UP_CREDIT]({});
      } else {
        let invoiceIDs = [];
        let debitNoteIDs = [];
        for (const invoice of this.selectedInvoices) {
          if (invoice.debitNoteId) {
            debitNoteIDs.push(invoice.debitNoteId);
          } else {
            invoiceIDs.push(invoice.invoiceId);
          }
        }

        const paramsCreatePayment = {
          invoiceIDs,
          debitNoteIDs,
          paymentTypeId: this.isFullyPaidCustBalance
            ? INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT
            : this.paymentType,
          totalPaidAmount: parseFloat(this.totalPaidAmount.toFixed(2)),
          balanceAllocationAmount: this.customerCreditAllocation,
          stripeCardId:
            this.paymentType === INVOICE_PAYMENT_TYPE.CUSTOMER_CREDIT || this.isFullyPaidCustBalance
              ? null
              : this.digitalCardIdSelected,
          stripeCustomerId: null
        };
        await this[ACTIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS]({ paramsCreatePayment });
      }
    },
    async submitManualPayment() {
      await this[ACTIONS.SET_SELECTED_PAYMENT_INVOICES]({
        selectedInvoices: this.selectedInvoices,
        totalInvoiceAmount: +this.totalInvoicesAmount.toFixed(2),
        availableCreditAmount: +this.availableCreditAmount.toFixed(2),
        overdueAmount: +this.totalOverdue.toFixed(2),
        overPayment: +this.totalOverpayment.toFixed(2),
        totalPaidAmount: +this.totalPaidAmount.toFixed(2),
        customerCreditAllocation: +this.customerCreditAllocation.toFixed(2)
      });
      await this[ACTIONS.SET_PARAMS_TOP_UP_CREDIT]({
        paramsTopUpCredit: {
          ...this.paramsTopUpCredit,
          paymentTypeId: this.paymentType
        }
      });
      this.$router.push({
        path: '/sale/invoices/payment-details',
        query: {
          'payment-type': this.paymentType
        }
      });
    }
  }
});
</script>

<style lang="scss" scoped>
.i-help {
  color: #eb8c31;
  font-size: 22px;
}
.bor-15 {
  --border-radius: 15px;
}
ion-toggle {
  height: 32px;
  width: 50px;
  --handle-background: #fff;
  --handle-background-checked: #fff;
  --background-checked: #00676a;
  --handle-width: 27px;
  --handle-height: 40px;
  --handle-border-radius: 16px;
  --handle-max-height: 28px;
  --handle-spacing: 2px;
  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
  contain: none;
}
</style>
